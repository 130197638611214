



























import { Component, Prop, Vue } from "vue-property-decorator";
import {
  TitleTemplatePublic,
  RedCapital,
  SelectedCountry,
  RedCapitalConfig,
  State
} from "@/configuration";

@Component({
  metaInfo: {
    title: "Servicio suspendido",
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
  data() {
    return {
      RedCapital,
      SelectedCountry
    };
  }
})
export default class NotFound extends Vue {}
